import React, { memo, useMemo } from 'react'
import { typePointToIconSvg } from '~src/new/entities/Points'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'
import Text from '~src/new/shared/ui/Text/Text'
import classNames from 'classnames'

import cls from './bonus-badge.module.scss'
import IntlUtil from '~shared/utils/IntlUtil'

const BonusBadge = memo(
  /**
   * @param {Object} props
   * @param {{ amount: number|string, type: string, id: string }[]} props.points
   * @param {"small" | "large"} [props.variant]
   */
  ({ points, variant }) => {
    const pointsComponents = useMemo(
      () =>
        points.map((point, index) => {
          const { amount, type, id } = point

          const isCrowns = id === 'business' || id === 'businessrrp'

          const size = isCrowns ? '16px' : '17px'

          let value = Number.isFinite(amount)
            ? id === 'business'
              ? IntlUtil.roundNum(amount)
              : // @ts-ignore
                IntlUtil.num(parseInt(amount))
            : '-'

          if (value === '-' && amount !== '-') value = '0'

          return (
            <div key={index} className={cls.point}>
              <Text variant="MAIN" className={cls.balance}>
                {value}
              </Text>
              <SvgIcon
                icon={typePointToIconSvg[type] ?? typePointToIconSvg.default}
                name={'point type'}
                style={{ width: size, height: size, top: isCrowns ? '-1px' : '0px' }}
              />
            </div>
          )
        }),
      [points],
    )

    return <div className={classNames(cls.balanceContainer, cls[variant])}>{pointsComponents}</div>
  },
)

export default BonusBadge
