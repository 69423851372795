import PortalLabes from '~src/constants/PortalLabels'
import Colors from '~shared/assets/styles/colors'

const getAmountByType = (points, type = 'default') => {
  const found = points.find(({ type: t }) => t === type)
  return (found && found.amount) || 0
}

const getPointsLabel = (type = 'default') =>
  `${PortalLabes.J_POINTS}${type === 'default' ? '' : `_${type.toUpperCase()}`}`

const styles = {
  primary: {
    color: Colors.Brand,
  },
  secondary: {
    color: Colors.Yellow,
  },
  tertiary: {
    color: Colors.GrassGreen,
  },
  quaternery: {
    color: Colors.Violet,
  },
}

const getColorLevelByType = (points, type) => {
  const point = Array.isArray(points) && points.find(({ type: pointsType }) => pointsType === type)
  const { color = 'primary' } = point || {}
  return color
}

const getColorByType = (points, type) => {
  const colorLevel = getColorLevelByType(points, type)
  return (styles[colorLevel] || styles.primary).color
}

export { getAmountByType, getPointsLabel, getColorByType, getColorLevelByType, styles }
