import AnalyticsEvents from '~shared/utils/AnalyticsEvents'

const HEADERS_FOR_SENS_API = {
  'Cache-Control': 'max-age=0',
}

export default class PortalApi {
  /**
   * @param {ApiConnector} connector
   */
  constructor(connector) {
    this.connector = connector
  }

  register = data => {
    return this.connector.post(`/@/member/register`, data)
  }

  login = data => {
    return this.connector.post(`/@/member/auth`, data, {
      headers: HEADERS_FOR_SENS_API,
    })
  }

  loginSSO = () => {
    return this.connector.get(`/@/sso/initsso`)
  }

  logout = async () => {
    let { request } = this.connector.post(`/@/member/logout`)
    await request
    window.location.reload(true)
  }

  messageContact = data => {
    AnalyticsEvents.pushContactEvent(data)
    return this.connector.post(`/@/message/contact`, data)
  }

  feedback = data => {
    return this.connector.post(`/@/feedback`, data)
  }

  resetPassword = data => {
    return this.connector.post(`/@/member/resetPassword`, data, {
      headers: HEADERS_FOR_SENS_API,
    })
  }

  setPassword = data => {
    return this.connector.post(`/@/member/setPassword`, data, {
      headers: HEADERS_FOR_SENS_API,
    })
  }

  orderReward = ({ amount, rewardId }) => {
    return this.connector.post(`/@/reward/order`, {
      form: {
        amount,
        rewardId,
      },
    })
  }

  selectDistributor = distributorCode => {
    return this.connector.post(`/@/products/distributor`, { distributorCode })
  }

  updateCart = (distributorCode, products) => {
    return this.connector.post(`/@/products/updateCart`, { distributorCode, products })
  }

  updateCartProduct = data => {
    return this.connector.post(`/@/products/updateCartProduct`, data)
  }

  verifyTrackingData = data => {
    return this.connector.post(`/@/products/verifyTrackingData`, data)
  }

  orderProducts = data => {
    return this.connector.post(`/@/products/order`, data)
  }

  cancelProductOrder = oid => {
    return this.connector.post(`/@/products/cancel`, { oid })
  }

  repeatOrder = oid => {
    return this.connector.post(`/@/products/repeat`, { oid })
  }

  orderSpecialOffer = offers => {
    return this.connector.post(`/@/specialoffers/order`, {
      form: { offers },
    })
  }

  sendRequestAnswers = data => {
    return this.connector.post(`/@/surveys/sendAnswers`, { form: data })
  }

  linkReference = data => {
    return this.connector.post(`/@/link`, data)
  }

  changePassword = data => {
    AnalyticsEvents.pushEvent('accountChange')
    return this.connector.post(`/@/member/changePassword`, data)
  }

  changeProfile = data => {
    AnalyticsEvents.pushEvent('accountChange')
    return this.connector.post(`/@/member/changeProfile`, data)
  }

  changeNotificationSettings = data => {
    return this.connector.post(`/@/member/changeNotificationSettings`, data)
  }

  changeSelectedAccount = data => {
    return this.connector.post(`/@/member/changeSelectedAccount`, { form: data })
  }

  changeSettings = data => {
    return this.connector.post(`/@/member/changeSettings`, data)
  }

  changeStoreInformation = data => {
    return this.connector.post(`/@/member/changeStoreInformation`, data)
  }

  quizAnswer = data => {
    return this.connector.post(`/@/eduaction/quizanswer`, {
      form: data,
    })
  }

  quizParticipation = data => {
    return this.connector.post(`/@/eduaction/quizparticipation`, {
      form: data,
    })
  }

  notificationInteraction = data => {
    AnalyticsEvents.pushEvent('liveBox')
    return this.connector.post(`/@/notifications/interaction`, { form: data })
  }

  staff = () => {
    return this.connector.get(`/@/member/staff`)
  }

  documents = () => {
    return this.connector.get(`/@/member/documents`)
  }

  downloadDocument = id => {
    window.location.href = `${this.connector.baseUrl}/@/member/documents/${id}`
  }

  downloadUserUpload = id => {
    window.location.href = `${this.connector.baseUrl}/@/member/uploads/${id}`
  }

  deleteUserUpload = id => {
    return this.connector.delete(`/@/member/uploads/${id}`)
  }

  uploadUserFile = file => {
    const formData = new FormData()
    formData.append('fileData', file)

    return this.connector.post('/@/member/uploads', formData)
  }

  timeChallenge = data => {
    return this.connector.post(`/@/eduaction/timechallenge`, {
      form: data,
    })
  }

  pointsChallenge = data => {
    return this.connector.post(`/@/eduaction/pointschallenge`, {
      form: data,
    })
  }

  uploadChallenge = (file, challengeId, geotag = {}, exifDateTime) => {
    const formData = new FormData()
    formData.append('uploadData', file)
    formData.append('uploadDataName', file.name)
    formData.append('uploadLastModified', file.lastModified)
    formData.append('challengeId', challengeId)
    exifDateTime && formData.append('exifDateTime', exifDateTime)

    let geoParsed = {}

    if (geotag.coords) {
      geoParsed = {
        accuracy: geotag.coords.accuracy,
        altitude: geotag.coords.altitude,
        altitudeAccuracy: geotag.coords.altitudeAccuracy,
        heading: geotag.coords.heading,
        latitude: geotag.coords.latitude,
        longitude: geotag.coords.longitude,
        speed: geotag.coords.speed,
        timestamp: geotag.timestamp,
      }
    }

    formData.append('geotag', JSON.stringify(geoParsed))

    return this.connector.post('/@/eduaction/upload', formData)
  }

  downloadPdf = (data, rest) => {
    return this.connector.post(
      `/@/downloadPdf`,
      {
        form: {
          url: data,
          ...rest,
        },
      },
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      },
    )
  }

  createNewContact = data => {
    AnalyticsEvents.pushEvent('newAccount')
    return this.connector.post(`/@/member/createNewContact`, data)
  }

  updateContact = data => {
    return this.connector.put(`/@/member/updateContact`, data)
  }

  deleteUserInformation = data => {
    return this.connector.post(`/@/member/deleteUserInformation`, {
      form: data,
    })
  }

  deleteOtherUserInformation = data => {
    return this.connector.post(`/@/member/deleteOtherUserInformation`, {
      form: data,
    })
  }

  signupWith = data => {
    return this.connector.post(`/@/member/signupWith`, {
      form: data,
    })
  }

  requestProcessCode = id => {
    return this.connector.post(`/@/member/requestProcessCode`, {
      form: { id },
    })
  }

  verifyProcessCode = data => {
    return this.connector.post(`/@/member/verifyProcessCode`, {
      form: data,
    })
  }

  requestCode = id => {
    return this.connector.post(`/@/member/requestCode`, {
      form: { id },
    })
  }

  requestCodeForConsumer = (id, phoneNumber) => {
    return this.connector.post(`/@/member/requestCodeForConsumer`, {
      form: { id, phoneNumber },
    })
  }

  registerToken = token => {
    return this.connector.post(`/@/member/registerToken`, {
      form: { token },
    })
  }

  registerWebPushToken = webPushToken => {
    return this.connector.post(`/@/member/registerWebPushToken`, {
      form: { webPushToken },
    })
  }

  verifyCode = (id, verificationCode) => {
    return this.connector.post(`/@/member/verifyCode`, {
      form: { id, verificationCode },
    })
  }

  verifyCaptcha = (id, token) => {
    return this.connector.post('/@/verify_captcha', {
      form: { id, token },
    })
  }

  submitPinCode = (_id, code) => {
    return this.connector.post(`/@/pincode/submit`, {
      form: { _id, code },
    })
  }

  updatePackTrack = products => {
    return this.connector.post(`/@/packtrack/update`, {
      form: { products },
    })
  }

  convertPoints = data => {
    return this.connector.post(`/@/rewards/convertPoints`, {
      form: data,
    })
  }

  ruAcceptCondition = data => {
    return this.connector.post(`/@/ru/my-program/acceptConditions`, {
      form: data,
    })
  }

  ruOpenConditionSingingLightbox = data => {
    return this.connector.post(`/@/ru/my-program/openConditionSingingLightbox`, {
      form: data,
    })
  }

  ruAcceptAgreements = programType => {
    return this.connector.post(`/@/ru/my-program/acceptAgreements`, { programType })
  }

  ruAcceptComplianceReport = pointsType => {
    return this.connector.post(`/@/ru/my-program/acceptComplianceReport`, { pointsType })
  }

  ruOpenComplianceReport = programType => {
    return this.connector.post(`/@/ru/my-program/openComplianceReport`, { programType })
  }

  ruGetTask = _id => {
    return this.connector.get(`/@/ru/my-program/getTask/${_id}`)
  }

  ruTaskSubmission = data => {
    return this.connector.post(`/@/ru/my-program/taskSubmission`, {
      form: data,
    })
  }

  ruTaskPhotoUpload = async (taskId, itemId, photo, geoLocation, subaccountCode) => {
    const formData = new FormData()
    formData.append('uploadData', photo)
    formData.append('uploadDataName', photo.name)
    formData.append('uploadLastModified', photo.lastModified)
    formData.append('taskId', taskId)
    formData.append('itemId', itemId)
    if (subaccountCode) formData.append('subaccountCode', subaccountCode)
    formData.append('geotag', JSON.stringify(geoLocation))

    return this.connector.post(`/@/ru/my-program/taskPhotoUpload`, formData)
  }

  ruTaskDeletePhoto = async (taskId, itemId) => {
    return this.connector.post(`/@/ru/my-program/ruTaskDeletePhoto`, {
      form: {
        taskId,
        itemId,
      },
    })
  }

  ruApproveContacts = async contacts => {
    return this.connector.post(`/@/ru/approve-contacts`, {
      form: {
        contacts,
      },
    })
  }

  beAcceptDataUsage = data => {
    return this.connector.post(`/@/be/acceptDataUsage`, {
      form: data,
    })
  }

  plChangeTaxInfo = data => {
    return this.connector.post(`/@/pl/changeTaxInfo`, data)
  }

  plToggleContactStatus = data => {
    return this.connector.post(`/@/pl/toggleContactStatus`, data)
  }

  usProcessMyForm = data => {
    return this.connector.post(`/@/us/my-forms/processMyForm`, data)
  }

  validateConsumer = data => {
    return this.connector.post(`/@/consumer/validateConsumer`, data)
  }

  validateConsumerExistence = data => {
    return this.connector.post(`/@/consumer/validateConsumerExistence`, data)
  }

  profileSelection = data => {
    return this.connector.post(`/@/consumer/profileSelection`, data)
  }

  requiredDataValidation = data => {
    return this.connector.post(`/@/consumer/requiredDataValidation`, data)
  }

  submitConsumer = data => {
    return this.connector.post(`/@/consumer/submitConsumer`, data)
  }

  completeConsumerSurvey = data => {
    return this.connector.post(`/@/consumer/completeConsumerSurvey`, data)
  }

  trialSubmitConsumerQrCode = data => {
    return this.connector.post(`/@/consumertrials/submitConsumerQrCode`, data)
  }
  trialStart = data => {
    return this.connector.post(`/@/consumertrials/start`, data)
  }
  trialBuyDevice = data => {
    return this.connector.post(`/@/consumertrials/buyDevice`, data)
  }
  trialEnd = data => {
    return this.connector.post(`/@/consumertrials/end`, data)
  }

  kzAcceptContract = data => {
    return this.connector.post(`/kz/api/acceptContract`, data)
  }
  kzRejectContract = data => {
    return this.connector.post(`/kz/api/disagreeWithContract`, data)
  }

  openNotification = _id => {
    return this.connector.get(`/@/ru/notification/read/${_id}`)
  }

  updateAttentionNotification = () => {
    return this.connector.get(`/@/ru/member/attention-notification`)
  }
  updateOnboardingSeen = async id => {
    return this.connector.post(`/@/ru/member/onboarding-seen`, { id })
  }

  getSearchResults = (search, page) => {
    return this.connector.post(`/@/ru/member/search-results`, { search, page })
  }

  saveSearchHistory = id => {
    return this.connector.get(`/@/ru/member/search-results/${id}`)
  }

  getSearchHistory = () => {
    return this.connector.get(`/@/ru/member/search-history`)
  }
}
